<template>
  <v-dialog v-model="dialog" fullscreen persistent class="print-modal">
    <PrintDocument :doc_form="doc_form"
                   :doc_id="doc_id"
                   :doc_type="doc_type"
                   :modal="dialog"
                   @closeModal="closeDialog"></PrintDocument>
  </v-dialog>
</template>

<script>
export default {
  name: "DocumentPrintModalWrapper",
  components: {
    PrintDocument: () => import("@/components/PrintDocument")
  },
  props: {
    value: {
      type: Boolean,
      default() {
        return false
      }
    },
    doc_type: {
      type: String,
      default() {
        return ""
      }
    },
    doc_form: {
      type: String,
      default() {
        return ""
      }
    },
    doc_id: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(payload) {
        this.dialog = payload
      }
    }
  }

}
</script>

<style scoped>

</style>